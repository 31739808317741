<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Tambah Konfigurasi Ruangan TUK",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Tambah Konfigurasi Ruangan TUK",
      items: [
        {
          text: "Konfigurasi",
          href: "/",
        },
        {
          text: "Konfigurasi Ruangan TUK",
          href: "/master/elemen",
        },
        {
          text: "Tambah Konfigurasi Ruangan TUK",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,

      // variabel referensi
      optionsTUK: [],
      optionsRuangan: [],

      // variable Page
      tuk_selected: "",
      ruangan_selected: "",
    };
  },
  mounted() {
    let self = this;
    self.getDataTUK();
    self.getDataRuangan();
  },
  methods: {
    getDataTUK(){
      let self = this;

      // load data menu parent
      var config_data_menu_parent = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-tuk",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config_data_menu_parent)
        .then(function (response) {
          var response_data = response.data;
          var response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.optionsTUK = response.data.data.referensi;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getDataRuangan(){
      let self = this;

      // load data 
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-ruangan",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          var response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.optionsRuangan = response.data.data.referensi;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/tuk-room-detail",
        data: {
          tuk_id: self.tuk_selected?.id,
          tuk_room_id: self.ruangan_selected?.id,
          tuk_detail_nomor: self.tuk_detail_nomor,
          tuk_detail_nama: self.tuk_detail_nama,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master konfig ruangan tuk segera",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "all-konfigurasi_ruangan_tuk" });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data.error;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' ||
                  axiosCatchError instanceof String
                  ">
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    {{ errorArray }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Tempat Uji Kompetensi</label>
                    <v-select 
                      :options="optionsTUK" 
                      label="tuk_nama"
                      v-model="tuk_selected"
                      placeholder="Pilih Tempat Uji Kompetensi">
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Ruangan</label>
                    <v-select 
                      :options="optionsRuangan" 
                      label="room_nama"
                      v-model="ruangan_selected"
                      placeholder="Pilih Ruangan">
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-12 col-md-6">
                  <div class="text-end">
                    <b-button type="reset" class="m-1" variant="danger"><i class="fas fa-redo-alt"></i> Reset</b-button>
                    <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> Simpan Konfig Ruangan TUK</b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout></template>
